export const PACKAGE_REGION = 'package_region';
export const HOTEL_REGION = 'hotel_region';
export const HOMESTAY_REGION = 'homestay_region';
export const PACKAGE_ACTIVITY = 'package_activity';
export const PACKAGE = 'package';
export const HOTEL = 'hotel';
export const HOMESTAY = 'homestay';
export const HOMESTAY_ROOM = 'room';
export const AMENITY = 'amenity';
export const BANNER = 'banner';
export const PROFILE = 'profile';
export const TOP_DEAL = 'top_deal';
export const BLOG = 'blog';
export const CMS_PAGE = 'page';
export const FEATURE = 'feature';
export const NOTICE = 'notice';
