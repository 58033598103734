import axios from 'axios';
import template from 'lodash/fp/template';
export const FILE_UPLOAD_URL = '/v1/admin/files';

export const FILE_UPDATE_URL = '/v1/admin/files/${id}'
export const FILE_DELETE_URL = '/v1/admin/files/${id}'
export const FILE_FEATURED_URL = '/v1/admin/featured-files'
export const FILE_CONTENT_URL = '/v1/admin/content-files'

/**
 * Store Image
 */
export const storeImage = (data) => {
    return axios.post(FILE_UPLOAD_URL, data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}

/**
 * Update Image
 * @param {number} id
 * @param {object} data
 */
export const updateImage = (id, data) => {
    return axios.post(template(FILE_UPDATE_URL)({ id }), data, {
        headers: {
            'content-type': 'multipart/form-data',
        }
    });
}

/**
 * Delete image
 *
 * @param {number} id
 */
export const destroyImage = (id) => {
    return axios.delete(template(FILE_DELETE_URL)({ id }));
}

/**
 * Make Featured image
 *
 * @param {number} id
 */
export const makeFeaturedImage = (data) => {
    return axios.post(FILE_FEATURED_URL, data);
}

/**
 * Upload content image
 *
 * @param {object} data
 */
export const uploadContentImage = (file) => {

    const formData = new FormData();
    formData.append("images[]", file);
    formData.append("entity", 'page');

    return axios.post(FILE_CONTENT_URL, formData, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
}
